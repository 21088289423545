import React from "react"
import { graphql } from "gatsby"

import Layout from "../shared/components/Layout/Layout"
import Seo from "../Seo/Seo"
import HomeHero from "../HomeHero/HomeHero"
import HomeProject from "../HomeProject/HomeProject"

import "../HomeProject/home-dots.css"

function IndexPage({ path, data }) {
  const projects = data.caseStudies.edges
  const projectsRef = React.useRef()

  function scrollToProjects() {
    projectsRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <Seo
        keywords={[
          `ux`,
          `ui`,
          `design`,
          `redesign`,
          `mayse falsiroli`,
          `case study`,
        ]}
      />
      <HomeHero scrollToProjects={scrollToProjects} />
      <div ref={projectsRef}>
        {projects.map(({ node }, index) => (
          <HomeProject
            key={node.uid}
            uid={node.uid}
            index={index}
            desktopImage={node.data.desktop_image.url}
            desktopBackgroundImage={node.data.desktop_background_image.url}
            title={node.data.title}
            description={node.data.description}
            backgroundColor={node.data.background_color}
            backgroundColorSecondary={node.data.background_color_secondary}
            textColor={node.data.text_color}
          />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    caseStudies: allPrismicCaseStudy(
      sort: { fields: [last_publication_date], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            published
            text_color
            background_color
            title
            description
            desktop_image {
              url
            }
            desktop_background_image {
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
