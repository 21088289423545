import React from "react"
import { motion } from "framer-motion"

import Chevron from "../shared/components/Icons/Chevron"
import SocialList from "../shared/components/SocialList/SocialList"
import { containerAnimation } from "../shared/utils/motionAnimations"
import styles from "./home-hero.module.scss"

export default function HomeHero({ scrollToProjects }) {
  return (
    <motion.section
      className={styles.hero}
      variants={containerAnimation}
      initial="initial"
      animate="animate"
    >
      <div className={styles.container}>
        <h1 className={styles.title}>
          Hello! <br />
          my name is <span>Mayse</span>
        </h1>
        <p className={styles.description}>
          I'm a <span>UX/UI DESIGNER</span>, based in London, passionate about
          creating meaningful experiences for people. My studies taught me the
          importance of empathy when solving any design challenge.
        </p>
        <SocialList />
      </div>
      <motion.button
        onClick={scrollToProjects}
        className={styles.scrollButton}
        animate={{ y: 4 }}
        transition={{
          yoyo: Infinity,
          ease: "easeInOut",
        }}
      >
        <Chevron />
      </motion.button>
    </motion.section>
  )
}
