import React, { useState, useRef, useLayoutEffect } from "react"
import classnames from "classnames"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { Link } from "gatsby"

import styles from "./home-project.module.scss"

export default function HomeProject({
  uid,
  index,
  desktopImage,
  desktopBackgroundImage,
  title,
  description,
}) {
  const [projectBackgroundTop, setProjectBackgroundTop] = useState(0)
  const [projectImageTop, setProjectImageTop] = useState(0)
  const projectRef = useRef(null)
  const { scrollY } = useViewportScroll()

  const projectBackgroundParallax = useTransform(
    scrollY,
    [projectBackgroundTop, projectBackgroundTop + 1],
    [0, -1],
    {
      clamp: false,
    }
  )
  // const projectImageParallax = useTransform(
  //   scrollY,
  //   [projectImageTop, projectImageTop + 1],
  //   [0, -0.2],
  //   {
  //     clamp: false,
  //   }
  // )

  useLayoutEffect(() => {
    const element = projectRef.current
    setProjectBackgroundTop(element.offsetTop)
    setProjectImageTop(element.offsetTop)
  }, [projectRef])

  return (
    <section className={styles.project} ref={projectRef}>
      <motion.div
        className={styles.backgroundImage}
        style={{
          backgroundImage: `url("${desktopBackgroundImage}")`,
          y: projectBackgroundParallax,
        }}
      />
      <div className={styles.container}>
        <div className={styles.description}>
          <h3 className={styles.title}>
            <span>0{index + 1}. </span>
            {title}
          </h3>
          <div className={styles.border} />
          <p className={styles.text}>{description}</p>
          <Link
            className={styles.link}
            to={uid}
            alt={`See ${title} case study`}
          >
            See Case Study
          </Link>
        </div>

        <img
          src={desktopImage}
          alt={title}
          title={title}
          className={classnames(styles.projectImage, styles.desktopImage)}
        />
      </div>
    </section>
  )
}
