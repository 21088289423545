import React from "react"

export default function Chevron() {
  return (
    <svg
      width="32px"
      height="16px"
      viewBox="0 0 32 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-943.000000, -1010.000000)" fill="#FFFFFF">
          <path
            d="M967.303645,1017.6354 L954.01933,1002.49305 C953.637211,1002.13009 953.03542,1002.13959 952.672465,1002.51212 C952.319002,1002.88466 952.319002,1003.46738 952.672465,1003.83991 L965.2786,1018.30409 L952.691539,1032.68491 C952.30942,1033.04787 952.299927,1033.65924 952.672465,1034.03178 C953.03542,1034.4139 953.646793,1034.42339 954.01933,1034.05085 C954.025718,1034.04446 958.453823,1029.02163 967.303645,1018.98236 C967.676182,1018.60973 967.676182,1018.00794 967.303645,1017.6354 Z"
            transform="translate(959.990976, 1018.275543) rotate(90.000000) translate(-959.990976, -1018.275543) "
          ></path>
        </g>
      </g>
    </svg>
  )
}
